import React, { Component } from 'react'
import ReportComponant from './ReportComponant'

export class ReportContainer extends Component {
    render() {
        return (
            <div>
                <ReportComponant />
            </div>
        )
    }
}

export default ReportContainer
