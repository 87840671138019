import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { Component } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import withMediaQuery from '../../features/hoc/withMediaQuery';
// import { Colors } from '../../styles/colors';
// import { withStore } from '../../utils/store.util';
// import { cartTranslation, deliveryTranslation } from '../../ utils/Translation';
import azFlag from "../../../images/country-flags/Azerbaijan.png";
import { customFlagList } from "./FlagList";
import SearchIcon from "@material-ui/icons/Search";
export class FlagInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openList: false,
      countryList: customFlagList,
      search: "",
      countryIcon: this.props.countryFlag || azFlag,
      countryCode: this.props.countryCode || "+994",
    };
  }

  openList = () => {
    try {
      const { showList } = this.props;
      this.setState({ openList: showList });
    } catch (error) {
      console.log(error);
    }
  };

  handleClose = () => {
    try {
      const { showList } = this.props;
      this.setState({ openList: showList, countryList: customFlagList });
    } catch (error) {
      console.log(error);
    }
  };

  filterCountries = (e) => {
    const filter = customFlagList.filter((phone) => {
      return (
        phone.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
        phone.dial_code.indexOf(e.target.value) !== -1 ||
        phone.code.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      countryList: filter,
      search: e.target.value,
    });
  };

  onClickItem = (country, event) => {
    try {
      this.props.stateToggleHandler("openFlagList");
      this.props.customerFormOnChangeHandler("countryCode", country.dial_code);
      this.props.customerFormOnChangeHandler("countryFlag", country.flag);
      this.setState({
        countryIcon: country.flag,
        countryCode: country.dial_code,
        countryList: customFlagList,
      });
      this.props.handleChangeMobileCode(country.dial_code, event);
    } catch (error) {
      console.log(error);
    }
  };

  setLocalizeContent = (obj) => {
    return obj[this.props.store.get("language")];
  };

  render() {
    return (
      <div className={`mobile-input-background-en ${this.props.className}`}>
        <div
          className={"cursor-pointer"}
          onClick={() => this.props.stateToggleHandler("openFlagList")}
        >
          <img
            src={this.state.countryIcon}
            className="mobile-container-flag"
            alt=""
          />
        </div>

        <div
          className={"cursor-pointer-caret"}
          onClick={() => this.props.stateToggleHandler("openFlagList")}
        >
          <ArrowDropDownIcon />
        </div>
        <div style={{ paddingTop: "6px" }}>
          <span className="mobile-container-countrycode">
            {this.state.countryCode}
          </span>
        </div>
        <div className="mobile-container-input-container">
          <form
            action="submit"
            autoComplete="off"
            onSubmit={this.props.getUserByPhone}
          >
            <input
              maxLength={15}
              type={"number"}
              className="mobile-container-input"
              value={this.props.phone}
              style={{ fontSize: "14px", paddingTop: "5px" }}
              name={"phone"}
              onChange={(event) => this.props.handleChangeMobile(event)}
              onWheelCapture={(e) => e.target.blur()}
              onKeyDown={(e) => {
                if (e.which === 38 || e.which === 40) {
                  e.preventDefault();
                }
              }}
              onKeyPress={(e) => {
                if (
                  e.charCode === 45 ||
                  e.charCode === 43 ||
                  e.charCode === 101 
                ) {
                  e.preventDefault();
                }
              }}
            />
          </form>
        </div>
        <Dialog
          open={this.props.openFlagList}
          maxWidth={"sm"}
          fullScreen={this.props.matches}
          onClose={() => this.props.stateToggleHandler("openFlagList")}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              paddingTop: "12px",
              paddingLeft: "0px",
              paddingRight: "0px",
              paddingBottom: "0px",
            }}
          >
            <span style={{ fontSize: "14px", float: "left", padding: "10px" }}>
              {/* { this.setLocalizeContent(deliveryTranslation[17] || "") } */}
              Cancel
            </span>
            <span
              onClick={() => this.props.stateToggleHandler("openFlagList")}
              style={{
                fontSize: "14px",
                float: "right",
                padding: "10px",
                cursor: "pointer",
                color: "rgb(70 70 70)",
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            >
              Search
            </span>

            <div
              style={{
                paddingTop: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <TextField
                fullWidth
                autoFocus
                InputLabelProps={{ shrink: true }}
                variant={"standard"}
                style={{
                  fontSize: "12px",
                }}
                onChange={(e) => this.filterCountries(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 15 }}
              />
            </div>
          </DialogTitle>
          <DialogContent className={"mobile-list-width"}>
            <div className={"mobile-list"}>
              {this.state.search === "" ? (
                <div
                  className={"flag-item"}
                  onClick={() => this.onClickItem(this.state.countryList[15])}
                >
                  <Grid
                    container
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid xs={1} item>
                      <img
                        className={"mobile-flag-icon"}
                        src={(this.state.countryList[15] || []).flag}
                        alt=""
                      />
                    </Grid>
                    <Grid
                      xs={8}
                      item
                      style={{ fontWeight: "bold", paddingLeft: "10px" }}
                    >
                      {(this.state.countryList[15] || []).name}
                    </Grid>
                    <Grid
                      xs={3}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {(this.state.countryList[15] || []).dial_code}
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              {(this.state.countryList || []).map((_country, index) => {
                return (
                  <div key={index}>
                    {_country.title ? (
                      <div
                        key={_country.dial_code}
                        style={{
                          background: "#FFF",
                          color: "#3c3c3c",
                          padding: "10px",
                          fontWeight: "bolder",
                          textTransform: "uppercase",
                        }}
                      >
                        {_country.name}
                      </div>
                    ) : (
                      <div
                        className={"flag-item"}
                        onClick={() => this.onClickItem(_country)}
                        key={_country.dial_code}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid xs={1} item>
                            <img
                              className={"mobile-flag-icon"}
                              src={_country.flag}
                              alt=""
                            />
                          </Grid>
                          <Grid
                            xs={8}
                            item
                            style={{ fontWeight: "bold", paddingLeft: "10px" }}
                          >
                            {_country.name}
                          </Grid>
                          <Grid
                            xs={3}
                            item
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {_country.dial_code}
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default FlagInput;
