import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import loader from '../../images/Loader/loader.gif';

export function Loader(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        PaperProps={{
          style: {
            background: 'transparent',
            boxShadow: 'none',
            zIndex: 99999999,
          },
        }}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <img
            src={loader}
            alt={'loading...'}
            style={{
              width: '100px',
              objectFit: 'contain',
              zIndex: 999999999,
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default Loader;
