import React from "react";
import IdleTimer from "react-idle-timer";
import { withRouter } from "react-router";
import apiServices from "../../services/requestHandler";
import localStoreUtil from "../../utils/localstore.util";
import { withStore } from "../../utils/store.util";
function IdleTimerContainer(props) {
  const idleTimerRef = React.useRef(null);

  const onIdle = async () => {
    try {
      const user = await localStoreUtil.get_data("agent");
      const _id = user._id;
      await apiServices.logoutApi(_id);
      props.store.setWithRender("logOutDailogModel", false);
      await localStoreUtil.remove_all();
      window.location.reload();
    } catch (error) {}
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={900 * 1000} // 15 min * secend 900 * milisecond
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
}

export default withStore(withRouter(IdleTimerContainer));
