import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, FormHelperText, TextField } from "@material-ui/core";
// styles
import useStyles from "./styles";
import img from "./bg-1.jfif";
import logo from "./login.png";
import userInput from "./user.png";
import pass from "./Pass.png";
import confirmpass from "./confirmPass.png";
import { InputAdornment } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import apiServices from "../../services/requestHandler";
import localStoreUtil from "../../utils/localstore.util";
import { toast } from "react-toastify";

function NewPassword(props) {
  var classes = useStyles();
  const [showPassword, setshowPassword] = useState(false);
  const [showPassword2, setshowPassword2] = useState(false);
  let [userID, setUserID] = useState(props?.history?.location?.state?.userID);
  let [userPass, setUserPass] = useState();
  let [conUserPass, setConUserPass] = useState();

  const onChangeHanler = (e) => {
    setUserID(e.target.value);
  };

  const onChangeHanler1 = (e) => {
    setUserPass(e.target.value);
  };

  const onChangeHanler2 = (e) => {
    setConUserPass(e.target.value);
  };

  const restLogInFun = async (e) => {
    e.preventDefault();
    if (userPass === conUserPass) {
      let data = {
        agentId: userID,
        password: userPass,
      };
      const response = await apiServices.restLogin(data);

      if (response.data.status === 200) {
        await localStoreUtil.store_data("agent", response.data.data);
        await localStoreUtil.store_data("token", response.data.data.token);
        props.history.push("/app/orders");
      } else {
        toast.success(response.data.error);
      }
    } else {
      toast.error("Password and Confirm Pasword Not Match");
    }
  };
  return (
    <>
      <form onSubmit={(e) => restLogInFun(e)} autoComplete="new-password">
        <div className={classes.overlay}>
          <img src={img} alt="background" className={classes.loginBackImg} />
          <div className={classes.logoLogin}>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} alt="Logo" className={classes.logoSize} />
              </div>

              <h1
                style={{
                  color: "white",
                  marginTop: "30px",
                  marginBottom: "15px",
                }}
              >
                &nbsp;&nbsp;&nbsp; SET NEW PASSWORD
              </h1>
              <div style={{ width: "100%" }}>
                <div>
                  <FormHelperText
                    id="standard-weight-helper-text"
                    className={classes.lableSize}
                    style={{ marginLeft: "50px" }}
                  >
                    User ID
                  </FormHelperText>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={userInput}
                      alt="inputUser"
                      className="input-fields-logo"
                    />
                    <TextField
                      disabled
                      id="outlined-basic"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      style={{ background: "gray" }}
                      className={classes.disabledBackground}
                      onChange={(event) => onChangeHanler(event)}
                      value={userID}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <FormHelperText
                    id="standard-weight-helper-text"
                    className={classes.lableSize}
                    style={{ marginLeft: "50px" }}
                  >
                    Enter Your Password
                  </FormHelperText>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={pass}
                      alt="inputUser"
                      className="input-fields-logo"
                    />
                    <TextField
                      id={showPassword ? "outlined-basic" : "outlined-basic-on"}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      className={classes.textBackground}
                      onChange={(event) => onChangeHanler1(event)}
                      type={"text"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setshowPassword(!showPassword);
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="userPass"
                      value={userPass}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <FormHelperText
                    id="standard-weight-helper-text"
                    className={classes.lableSize}
                    style={{ marginLeft: "50px" }}
                  >
                    Confirm Password
                  </FormHelperText>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={confirmpass}
                      alt="inputUser"
                      className="input-fields-logo"
                    />
                    <TextField
                      id={
                        showPassword2 ? "outlined-basic" : "outlined-basic-on"
                      }
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      className={classes.textBackground}
                      size="small"
                      onChange={(event) => onChangeHanler2(event)}
                      type={"text"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setshowPassword2(!showPassword2);
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="conUserPass"
                      value={conUserPass}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type={"submit"}
                      style={{
                        backgroundColor: "#0078AC",
                        fontFamily: "Bold",
                        fontSize: "20px",
                        color: "white",
                        marginTop: "20px",
                        marginLeft: "45px",
                        width: "70%",
                      }}
                      className={"btn-bg-container"}
                      variant={"contained"}
                      onClick={(e) => {
                        restLogInFun(e);
                      }}
                    >
                      SET PASSWORD
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default withRouter(NewPassword);
