export const customFlagList = [
    { name: 'a', title: true, code: 'a', dial_code: '-1' },
   /*1 */ { name: 'Afghanistan', flag: require("../../../images/country-flags/Afghanistan.png").default, code: "AF", dial_code: '+93' },
   /*2 */ { name: 'Åland Islands', flag: require("../../../images/country-flags/Aland.png").default, code: 'AX', dial_code: '+358' },
   /*3 */ { name: 'Albania', flag: require("../../../images/country-flags/Albania.png").default, code: 'AL', dial_code: '+355' },
   /*4 */ { name: 'Algeria', flag: require("../../../images/country-flags/Algeria.png").default, code: 'DZ', dial_code: '+213' },
   /*6 */ { name: 'American Samoa', flag: require("../../../images/country-flags/American-Samoa.png").default, code: 'AS', dial_code: '+1684' },
   /*7 */ { name: 'Andorra', flag: require("../../../images/country-flags/Andorra.png").default, code: 'AD', dial_code: '+376' },
   /*8 */ { name: 'Angola', flag: require("../../../images/country-flags/Angola.png").default, code: 'AO', dial_code: '+244' },
   /*9 */ { name: 'Anguilla', flag: require("../../../images/country-flags/Anguilla.png").default, code: 'AI', dial_code: '+1264' },
   /*10 */ { name: 'Antarctica', flag: require("../../../images/country-flags/Antarctica.png").default, code: 'AQ', dial_code: '+672' },
   /*11 */ { name: 'Antigua and Barbuda', flag: require("../../../images/country-flags/Antigua-and-Barbuda.png").default, code: 'AG', dial_code: '+1268' },
   /*12 */ { name: 'Argentina', flag: require("../../../images/country-flags/Argentina.png").default, code: 'AR', dial_code: '+54' },
   /*13 */ // {name: 'Armenia', flag: require("../../../images/country-flags/Armenia.png").default, code: 'AM', dial_code: '+374'},
   /*14 */ { name: 'Aruba', flag: require("../../../images/country-flags/Aruba.png").default, code: 'AW', dial_code: '+297' },
   /*15*/ { name: 'Australia', flag: require("../../../images/country-flags/Australia.png").default, code: 'AU', dial_code: '+61' },
   /*16 */ { name: 'Austria', flag: require("../../../images/country-flags/Austria.png").default, code: 'AT', dial_code: '+43' },
   /*17 */ { name: 'Azerbaijan', flag: require("../../../images/country-flags/Azerbaijan.png").default, code: 'AZ', dial_code: '+994' },
    { name: 'b', title: true, code: 'b', dial_code: '-1' },
    { name: 'Bahamas', flag: require("../../../images/country-flags/Bahamas.png").default, code: 'BS', dial_code: '+1242' },
    { name: 'Bahrain', flag: require("../../../images/country-flags/Bahrain.png").default, code: 'BH', dial_code: '+973' },
    { name: 'Bangladesh', flag: require("../../../images/country-flags/Bangladesh.png").default, code: 'BD', dial_code: '+880' },
    { name: 'Barbados', flag: require("../../../images/country-flags/Barbados.png").default, code: 'BB', dial_code: '+1246' },
    { name: 'Belarus', flag: require("../../../images/country-flags/Belarus.png").default, code: 'BY', dial_code: '+375' },
    { name: 'Belgium', flag: require("../../../images/country-flags/Belgium.png").default, code: 'BE', dial_code: '+32' },
    { name: 'Belize', flag: require("../../../images/country-flags/Belize.png").default, code: 'BZ', dial_code: '+501' },
    { name: 'Benin', flag: require("../../../images/country-flags/Benin.png").default, code: 'BJ', dial_code: '+229' },
    { name: 'Bermuda', flag: require("../../../images/country-flags/Bermuda.png").default, code: 'BM', dial_code: '+1441' },
    { name: 'Bhutan', flag: require("../../../images/country-flags/Bhutan.png").default, code: 'BT', dial_code: '+975' },
    {
        name: 'Bolivia, Plurinational State of bolivia',
        flag: require("../../../images/country-flags/Bolivia.png").default,
        code: 'BO',
        dial_code: '+591',
    },
    { name: 'Bosnia and Herzegovina', flag: require("../../../images/country-flags/Bosnia-and-Herzegovina.png").default, code: 'BA', dial_code: '+387' },
    { name: 'Botswana', flag: require("../../../images/country-flags/Botswana.png").default, code: 'BW', dial_code: '+267' },
    { name: 'Bouvet Island', flag: require("../../../images/country-flags/Norway.png").default, code: 'BV', dial_code: '+47' },
    { name: 'Brazil', flag: require("../../../images/country-flags/Brazil.png").default, code: 'BR', dial_code: '+55' },

    { name: 'Brunei Darussalam', flag: require("../../../images/country-flags/Brunei.png").default, code: 'BN', dial_code: '+673' },
    { name: 'Bulgaria', flag: require("../../../images/country-flags/Bulgaria.png").default, code: 'BG', dial_code: '+359' },
    { name: 'Burkina Faso', flag: require("../../../images/country-flags/Burkina-Faso.png").default, code: 'BF', dial_code: '+226' },
    { name: 'Burundi', flag: require("../../../images/country-flags/Burundi.png").default, code: 'BI', dial_code: '+257' },

    { name: 'c', title: true, code: 'c', dial_code: '-1' },

    { name: 'Cambodia', flag: require("../../../images/country-flags/Cambodia.png").default, code: 'KH', dial_code: '+855' },
    { name: 'Cameroon', flag: require("../../../images/country-flags/Cameroon.png").default, code: 'CM', dial_code: '+237' },
    { name: 'Canada', flag: require("../../../images/country-flags/Canada.png").default, code: 'CA', dial_code: '+1' },
    { name: 'Cape Verde', flag: require("../../../images/country-flags/Cape-Verde.png").default, code: 'CV', dial_code: '+238' },
    { name: 'Cayman Islands', flag: require("../../../images/country-flags/Cayman-Islands.png").default, code: 'KY', dial_code: '+345' },
    { name: 'Central African Republic', flag: require("../../../images/country-flags/Central-African-Republic.png").default, code: 'CF', dial_code: '+236' },
    { name: 'Chad', flag: require("../../../images/country-flags/Chad.png").default, code: 'TD', dial_code: '+235' },
    { name: 'Chile', flag: require("../../../images/country-flags/Chile.png").default, code: 'CL', dial_code: '+56' },
    { name: 'China', flag: require("../../../images/country-flags/China.png").default, code: 'CN', dial_code: '+86' },
    { name: 'Christmas Island', flag: require("../../../images/country-flags/Christmas-Island.png").default, code: 'CX', dial_code: '+61' },
    { name: 'Cocos (Keeling) Islands', flag: require("../../../images/country-flags/Cocos-Keeling-Islands.png").default, code: 'CC', dial_code: '+61' },
    { name: 'Colombia', flag: require("../../../images/country-flags/Colombia.png").default, code: 'CO', dial_code: '+57' },
    { name: 'Comoros', flag: require("../../../images/country-flags/Comoros.png").default, code: 'KM', dial_code: '+269' },
    { name: 'Congo', flag: require("../../../images/country-flags/Democratic-Republic-of-the-Congo.png").default, code: 'CG', dial_code: '+242' },
    {
        name: 'Congo, The Democratic Republic of the Congo',
        flag: require("../../../images/country-flags/Democratic-Republic-of-the-Congo.png").default,
        code: 'CD',
        dial_code: '+243',
    },
    { name: 'Cook Islands', flag: require("../../../images/country-flags/Cook-Islands.png").default, code: 'CK', dial_code: '+682' },
    { name: 'Costa Rica', flag: require("../../../images/country-flags/Costa-Rica.png").default, code: 'CR', dial_code: '+506' },
    { name: "Cote d'Ivoire", flag: require("../../../images/country-flags/Cote-dIvoire.png").default, code: 'CI', dial_code: '+225' },
    { name: 'Croatia', flag: require("../../../images/country-flags/Croatia.png").default, code: 'HR', dial_code: '+385' },
    { name: 'Cuba', flag: require("../../../images/country-flags/Cuba.png").default, code: 'CU', dial_code: '+53' },
    { name: 'Cyprus', flag: require("../../../images/country-flags/Cyprus.png").default, code: 'CY', dial_code: '+357' },
    { name: 'Czech Republic', flag: require("../../../images/country-flags/Czech-Republic.png").default, code: 'CZ', dial_code: '+420' },

    { name: 'd', title: true, code: 'd', dial_code: '-1' },
    { name: 'Denmark', flag: require("../../../images/country-flags/Denmark.png").default, code: 'DK', dial_code: '+45' },
    { name: 'Djibouti', flag: require("../../../images/country-flags/Djibouti.png").default, code: 'DJ', dial_code: '+253' },
    { name: 'Dominica', flag: require("../../../images/country-flags/Dominica.png").default, code: 'DM', dial_code: '+1767' },
    { name: 'Dominican Republic', flag: require("../../../images/country-flags/Dominican-Republic.png").default, code: 'DO', dial_code: '+1849' },

    { name: 'e', title: true, code: 'e', dial_code: '-1' },
    { name: 'Ecuador', flag: require("../../../images/country-flags/Ecuador.png").default, code: 'EC', dial_code: '+593' },
    { name: 'Egypt', flag: require("../../../images/country-flags/Egypt.png").default, code: 'EG', dial_code: '+20' },
    { name: 'El Salvador', flag: require("../../../images/country-flags/El-Salvador.png").default, code: 'SV', dial_code: '+503' },
    { name: 'Equatorial Guinea', flag: require("../../../images/country-flags/Equatorial-Guinea.png").default, code: 'GQ', dial_code: '+240' },
    { name: 'Eritrea', flag: require("../../../images/country-flags/Eritrea.png").default, code: 'ER', dial_code: '+291' },
    { name: 'Estonia', flag: require("../../../images/country-flags/Estonia.png").default, code: 'EE', dial_code: '+372' },
    { name: 'Ethiopia', flag: require("../../../images/country-flags/Ethiopia.png").default, code: 'ET', dial_code: '+251' },


    { name: 'f', title: true, code: 'f', dial_code: '-1' },
    {
        name: 'Falkland Islands (Malvinas)',
        flag: require("../../../images/country-flags/Falkland-Islands.png").default,
        code: 'FK',
        dial_code: '+500',
    },
    { name: 'Faroe Islands', flag: require("../../../images/country-flags/Faroes.png").default, code: 'FO', dial_code: '+298' },
    { name: 'Fiji', flag: require("../../../images/country-flags/Fiji.png").default, code: 'FJ', dial_code: '+679' },
    { name: 'Finland', flag: require("../../../images/country-flags/Finland.png").default, code: 'FI', dial_code: '+358' },
    { name: 'France', flag: require("../../../images/country-flags/France.png").default, code: 'FR', dial_code: '+33' },
    { name: 'French Guiana', flag: require("../../../images/country-flags/French-Southern-Territories.png").default, code: 'GF', dial_code: '+594' },
    { name: 'French Polynesia', flag: require("../../../images/country-flags/French-Polynesia.png").default, code: 'PF', dial_code: '+689' },

    { name: 'g', title: true, code: 'g', dial_code: '-1' },
    { name: 'Gabon', flag: require("../../../images/country-flags/Gabon.png").default, code: 'GA', dial_code: '+241' },
    { name: 'Gambia', flag: require("../../../images/country-flags/Gambia.png").default, code: 'GM', dial_code: '+220' },
    { name: 'Georgia', flag: require("../../../images/country-flags/Georgia.png").default, code: 'GE', dial_code: '+995' },
    { name: 'Germany', flag: require("../../../images/country-flags/Germany.png").default, code: 'DE', dial_code: '+49' },
    { name: 'Ghana', flag: require("../../../images/country-flags/Ghana.png").default, code: 'GH', dial_code: '+233' },
    { name: 'Gibraltar', flag: require("../../../images/country-flags/Gibraltar.png").default, code: 'GI', dial_code: '+350' },
    { name: 'Greece', flag: require("../../../images/country-flags/Greece.png").default, code: 'GR', dial_code: '+30' },
    { name: 'Greenland', flag: require("../../../images/country-flags/Greenland.png").default, code: 'GL', dial_code: '+299' },
    { name: 'Grenada', flag: require("../../../images/country-flags/Grenada.png").default, code: 'GD', dial_code: '+1473' },
    { name: 'Guadeloupe', flag: require("../../../images/country-flags/France.png").default, code: 'GP', dial_code: '+590' },
    { name: 'Guam', flag: require("../../../images/country-flags/Guam.png").default, code: 'GU', dial_code: '+1671' },
    { name: 'Guatemala', flag: require("../../../images/country-flags/Guatemala.png").default, code: 'GT', dial_code: '+502' },
    { name: 'Guernsey', flag: require("../../../images/country-flags/Guernsey.png").default, code: 'GG', dial_code: '+44' },
    { name: 'Guinea', flag: require("../../../images/country-flags/Guinea.png").default, code: 'GN', dial_code: '+224' },
    { name: 'Guinea-Bissau', flag: require("../../../images/country-flags/Guinea-Bissau.png").default, code: 'GW', dial_code: '+245' },
    { name: 'Guyana', flag: require("../../../images/country-flags/Guyana.png").default, code: 'GY', dial_code: '+592' },


    { name: 'h', title: true, code: 'h', dial_code: '-1' },
    { name: 'Haiti', flag: require("../../../images/country-flags/Haiti.png").default, code: 'HT', dial_code: '+509' },
    {
        name: 'Heard Island and Mcdonald Islands',
        flag: require("../../../images/country-flags/Australia.png").default,
        code: 'HM',
        dial_code: '+672',
    },
    {
        name: 'Holy See (Vatican City State)',
        flag: require("../../../images/country-flags/Vatican-City.png").default,
        code: 'VA',
        dial_code: '+379',
    },
    { name: 'Honduras', flag: require("../../../images/country-flags/Honduras.png").default, code: 'HN', dial_code: '+504' },
    { name: 'Hong Kong', flag: require("../../../images/country-flags/Hong-Kong.png").default, code: 'HK', dial_code: '+852' },
    { name: 'Hungary', flag: require("../../../images/country-flags/Hungary.png").default, code: 'HU', dial_code: '+36' },


    { name: 'i', title: true, code: 'i', dial_code: '-1' },
    { name: 'Iceland', flag: require("../../../images/country-flags/Iceland.png").default, code: 'IS', dial_code: '+354' },
    { name: 'India', flag: require("../../../images/country-flags/India.png").default, code: 'IN', dial_code: '+91' },
    { name: 'Indonesia', flag: require("../../../images/country-flags/Indonesia.png").default, code: 'ID', dial_code: '+62' },
    {
        name: 'Islamic Republic of Iran',
        flag: require("../../../images/country-flags/Iran.png").default,
        code: 'IR',
        dial_code: '+98',
    },
    { name: 'Iraq', flag: require("../../../images/country-flags/Iraq.png").default, code: 'IQ', dial_code: '+964' },
    { name: 'Ireland', flag: require("../../../images/country-flags/Ireland.png").default, code: 'IE', dial_code: '+353' },
    { name: 'Isle of Man', flag: require("../../../images/country-flags/Isle-of-Man.png").default, code: 'IM', dial_code: '+44' },
    { name: 'Israel', flag: require("../../../images/country-flags/Israel.png").default, code: 'IL', dial_code: '+972' },
    { name: 'Italy', flag: require("../../../images/country-flags/Italy.png").default, code: 'IT', dial_code: '+39' },

    { name: 'j', title: true, code: 'j', dial_code: '-1' },
    { name: 'Jamaica', flag: require("../../../images/country-flags/Jamaica.png").default, code: 'JM', dial_code: '+1876' },
    { name: 'Japan', flag: require("../../../images/country-flags/Japan.png").default, code: 'JP', dial_code: '+81' },
    { name: 'Jersey', flag: require("../../../images/country-flags/Jersey.png").default, code: 'JE', dial_code: '+44' },
    { name: 'Jordan', flag: require("../../../images/country-flags/Jordan.png").default, code: 'JO', dial_code: '+962' },

    { name: 'k', title: true, code: 'k', dial_code: '-1' },
    { name: 'Kazakhstan', flag: require("../../../images/country-flags/Kazakhstan.png").default, code: 'KZ', dial_code: '+7' },
    { name: 'Kenya', flag: require("../../../images/country-flags/Kenya.png").default, code: 'KE', dial_code: '+254' },
    { name: 'Kiribati', flag: require("../../../images/country-flags/Kiribati.png").default, code: 'KI', dial_code: '+686' },
    {
        name: "Korea, Democratic People's Republic of Korea",
        flag: require("../../../images/country-flags/North-Korea.png").default,
        code: 'KP',
        dial_code: '+850',
    },
    {
        name: 'Korea, Republic of South Korea',
        flag: require("../../../images/country-flags/South-Korea.png").default,
        code: 'KR',
        dial_code: '+82',
    },
    { name: 'Kosovo', flag: require("../../../images/country-flags/Kosovo.png").default, code: 'XK', dial_code: '+383' },
    { name: 'Kuwait', flag: require("../../../images/country-flags/Kuwait.png").default, code: 'KW', dial_code: '+965' },
    { name: 'Kyrgyzstan', flag: require("../../../images/country-flags/Kyrgyzstan.png").default, code: 'KG', dial_code: '+996' },

    { name: 'l', title: true, code: 'l', dial_code: '-1' },
    { name: 'Laos', flag: require("../../../images/country-flags/Laos.png").default, code: 'LA', dial_code: '+856' },
    { name: 'Latvia', flag: require("../../../images/country-flags/Latvia.png").default, code: 'LV', dial_code: '+371' },
    { name: 'Lebanon', flag: require("../../../images/country-flags/Lebanon.png").default, code: 'LB', dial_code: '+961' },
    { name: 'Lesotho', flag: require("../../../images/country-flags/Lesotho.png").default, code: 'LS', dial_code: '+266' },
    { name: 'Liberia', flag: require("../../../images/country-flags/Liberia.png").default, code: 'LR', dial_code: '+231' },
    { name: 'Libyan Arab Jamahiriya', flag: require("../../../images/country-flags/Libya.png").default, code: 'LY', dial_code: '+218' },
    { name: 'Liechtenstein', flag: require("../../../images/country-flags/Liechtenstein.png").default, code: 'LI', dial_code: '+423' },
    { name: 'Lithuania', flag: require("../../../images/country-flags/Lithuania.png").default, code: 'LT', dial_code: '+370' },
    { name: 'Luxembourg', flag: require("../../../images/country-flags/Luxembourg.png").default, code: 'LU', dial_code: '+352' },

    { name: 'm', title: true, code: 'm', dial_code: '-1' },
    { name: 'Macao', flag: require("../../../images/country-flags/Macau.png").default, code: 'MO', dial_code: '+853' },
    { name: 'Macedonia', flag: require("../../../images/country-flags/Macedonia.png").default, code: 'MK', dial_code: '+389' },
    { name: 'Madagascar', flag: require("../../../images/country-flags/Madagascar.png").default, code: 'MG', dial_code: '+261' },
    { name: 'Malawi', flag: require("../../../images/country-flags/Malawi.png").default, code: 'MW', dial_code: '+265' },
    { name: 'Malaysia', flag: require("../../../images/country-flags/Malaysia.png").default, code: 'MY', dial_code: '+60' },
    { name: 'Maldives', flag: require("../../../images/country-flags/Maldives.png").default, code: 'MV', dial_code: '+960' },
    { name: 'Mali', flag: require("../../../images/country-flags/Mali.png").default, code: 'ML', dial_code: '+223' },
    { name: 'Malta', flag: require("../../../images/country-flags/Malta.png").default, code: 'MT', dial_code: '+356' },
    { name: 'Marshall Islands', flag: require("../../../images/country-flags/Marshall-Islands.png").default, code: 'MH', dial_code: '+692' },
    { name: 'Martinique', flag: require("../../../images/country-flags/Martinique.png").default, code: 'MQ', dial_code: '+596' },
    { name: 'Mauritania', flag: require("../../../images/country-flags/Mauritania.png").default, code: 'MR', dial_code: '+222' },
    { name: 'Mauritius', flag: require("../../../images/country-flags/Mauritius.png").default, code: 'MU', dial_code: '+230' },
    { name: 'Mayotte', flag: require("../../../images/country-flags/Mayotte.png").default, code: 'YT', dial_code: '+262' },
    { name: 'Mexico', flag: require("../../../images/country-flags/Mexico.png").default, code: 'MX', dial_code: '+52' },
    {
        name: 'Micronesia, Federated States of Micronesia',
        flag: require("../../../images/country-flags/Micronesia.png"),
        code: 'FM',
        dial_code: '+691',
    },
    { name: 'Moldova', flag: require("../../../images/country-flags/Moldova.png").default, code: 'MD', dial_code: '+373' },
    { name: 'Monaco', flag: require("../../../images/country-flags/Monaco.png").default, code: 'MC', dial_code: '+377' },
    { name: 'Mongolia', flag: require("../../../images/country-flags/Mongolia.png").default, code: 'MN', dial_code: '+976' },
    { name: 'Montenegro', flag: require("../../../images/country-flags/Montenegro.png").default, code: 'ME', dial_code: '+382' },
    { name: 'Montserrat', flag: require("../../../images/country-flags/Montserrat.png").default, code: 'MS', dial_code: '+1664' },
    { name: 'Morocco', flag: require("../../../images/country-flags/Morocco.png").default, code: 'MA', dial_code: '+212' },
    { name: 'Mozambique', flag: require("../../../images/country-flags/Mozambique.png").default, code: 'MZ', dial_code: '+258' },
    { name: 'Myanmar', flag: require("../../../images/country-flags/Myanmar.png").default, code: 'MM', dial_code: '+95' },

    { name: 'n', title: true, code: 'n', dial_code: '-1' },
    { name: 'Namibia', flag: require("../../../images/country-flags/Namibia.png").default, code: 'NA', dial_code: '+264' },
    { name: 'Nauru', flag: require("../../../images/country-flags/Nauru.png").default, code: 'NR', dial_code: '+674' },
    { name: 'Nepal', flag: require("../../../images/country-flags/Nepal.png").default, code: 'NP', dial_code: '+977' },
    { name: 'Netherlands', flag: require("../../../images/country-flags/Netherlands.png").default, code: 'NL', dial_code: '+31' },
    { name: 'Netherlands Antilles', flag: require("../../../images/country-flags/Netherlands-Antilles.png").default, code: 'AN', dial_code: '+599' },
    { name: 'New Caledonia', flag: require("../../../images/country-flags/New-Caledonia.png").default, code: 'NC', dial_code: '+687' },
    { name: 'New Zealand', flag: require("../../../images/country-flags/New-Zealand.png").default, code: 'NZ', dial_code: '+64' },
    { name: 'Nicaragua', flag: require("../../../images/country-flags/Nicaragua.png").default, code: 'NI', dial_code: '+505' },
    { name: 'Niger', flag: require("../../../images/country-flags/Niger.png").default, code: 'NE', dial_code: '+227' },
    { name: 'Nigeria', flag: require("../../../images/country-flags/Nigeria.png").default, code: 'NG', dial_code: '+234' },
    { name: 'Niue', flag: require("../../../images/country-flags/Niue.png").default, code: 'NU', dial_code: '+683' },
    { name: 'Norfolk Island', flag: require("../../../images/country-flags/Norfolk-Island.png").default, code: 'NF', dial_code: '+672' },
    {
        name: 'Northern Mariana Islands',
        flag: require("../../../images/country-flags/Northern-Mariana-Islands.png").default,
        code: 'MP',
        dial_code: '+1670',
    },
    { name: 'Norway', flag: require("../../../images/country-flags/Norway.png").default, code: 'NO', dial_code: '+47' },

    { name: 'o', title: true, code: 'o', dial_code: '-1' },
    { name: 'Oman', flag: require("../../../images/country-flags/Oman.png").default, code: 'OM', dial_code: '+968' },

    { name: 'p', title: true, code: 'p', dial_code: '-1' },
    { name: 'Pakistan', flag: require("../../../images/country-flags/Pakistan.png").default, code: 'PK', dial_code: '+92' },
    { name: 'Palau', flag: require("../../../images/country-flags/Palau.png").default, code: 'PW', dial_code: '+680' },
    {
        name: 'Palestine',
        flag: require("../../../images/country-flags/Palestine.png").default,
        code: 'PS',
        dial_code: '+970',
    },
    { name: 'Panama', flag: require("../../../images/country-flags/Panama.png").default, code: 'PA', dial_code: '+507' },
    { name: 'Papua New Guinea', flag: require("../../../images/country-flags/Papua-New-Guinea.png").default, code: 'PG', dial_code: '+675' },
    { name: 'Paraguay', flag: require("../../../images/country-flags/Paraguay.png").default, code: 'PY', dial_code: '+595' },
    { name: 'Peru', flag: require("../../../images/country-flags/Peru.png").default, code: 'PE', dial_code: '+51' },
    { name: 'Philippines', flag: require("../../../images/country-flags/Philippines.png").default, code: 'PH', dial_code: '+63' },
    { name: 'Pitcairn', flag: require("../../../images/country-flags/Pitcairn-Islands.png").default, code: 'PN', dial_code: '+64' },
    { name: 'Poland', flag: require("../../../images/country-flags/Poland.png").default, code: 'PL', dial_code: '+48' },
    { name: 'Portugal', flag: require("../../../images/country-flags/Portugal.png").default, code: 'PT', dial_code: '+351' },
    { name: 'Puerto Rico', flag: require("../../../images/country-flags/Puerto-Rico.png").default, code: 'PR', dial_code: '+1939' },


    { name: 'q', title: true, code: 'q', dial_code: '-1' },
    { name: 'Qatar', flag: require("../../../images/country-flags/Qatar.png").default, code: 'QA', dial_code: '+974' },

    { name: 'r', title: true, code: 'r', dial_code: '-1' },
    { name: 'Romania', flag: require("../../../images/country-flags/Romania.png").default, code: 'RO', dial_code: '+40' },
    { name: 'Russia', flag: require("../../../images/country-flags/Russia.png").default, code: 'RU', dial_code: '+7' },
    { name: 'Rwanda', flag: require("../../../images/country-flags/Rwanda.png").default, code: 'RW', dial_code: '+250' },
    { name: 'Reunion', flag: require("../../../images/country-flags/Reunion.png").default, code: 'RE', dial_code: '+262' },

    { name: 's', title: true, code: 's', dial_code: '-1' },
    { name: 'Saint Barthelemy', flag: require("../../../images/country-flags/Saint-Barthelemy.png").default, code: 'BL', dial_code: '+590' },
    {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        flag: require("../../../images/country-flags/Saint-Helena.png").default,
        code: 'SH',
        dial_code: '+290',
    },
    { name: 'Saint Kitts and Nevis', flag: require("../../../images/country-flags/Saint-Kitts-and-Nevis.png").default, code: 'KN', dial_code: '+1869' },
    { name: 'Saint Lucia', flag: require("../../../images/country-flags/Saint-Lucia.png").default, code: 'LC', dial_code: '+1758' },
    { name: 'Saint Martin', flag: require("../../../images/country-flags/Saint-Martin.png").default, code: 'MF', dial_code: '+590' },
    {
        name: 'Saint Pierre and Miquelon',
        flag: require("../../../images/country-flags/France.png").default,
        code: 'PM',
        dial_code: '+508',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        flag: require("../../../images/country-flags/Saint-Vincent-and-the-Grenadines.png").default,
        code: 'VC',
        dial_code: '+1784',
    },
    { name: 'Samoa', flag: require("../../../images/country-flags/Samoa.png").default, code: 'WS', dial_code: '+685' },
    { name: 'San Marino', flag: require("../../../images/country-flags/San-Marino.png").default, code: 'SM', dial_code: '+378' },
    { name: 'Sao Tome and Principe', flag: require("../../../images/country-flags/Sao-Tome-and-Principe.png").default, code: 'ST', dial_code: '+239' },
    { name: 'Saudi Arabia', flag: require("../../../images/country-flags/Saudi-Arabia.png").default, code: 'SA', dial_code: '+966' },
    { name: 'Senegal', flag: require("../../../images/country-flags/Senegal.png").default, code: 'SN', dial_code: '+221' },
    { name: 'Serbia', flag: require("../../../images/country-flags/Serbia.png").default, code: 'RS', dial_code: '+381' },
    { name: 'Seychelles', flag: require("../../../images/country-flags/Seychelles.png").default, code: 'SC', dial_code: '+248' },
    { name: 'Sierra Leone', flag: require("../../../images/country-flags/Sierra-Leone.png").default, code: 'SL', dial_code: '+232' },
    { name: 'Singapore', flag: require("../../../images/country-flags/Singapore.png").default, code: 'SG', dial_code: '+65' },
    { name: 'Slovakia', flag: require("../../../images/country-flags/Slovakia.png").default, code: 'SK', dial_code: '+421' },
    { name: 'Slovenia', flag: require("../../../images/country-flags/Slovenia.png").default, code: 'SI', dial_code: '+386' },
    { name: 'Solomon Islands', flag: require("../../../images/country-flags/Solomon-Islands.png").default, code: 'SB', dial_code: '+677' },
    { name: 'Somalia', flag: require("../../../images/country-flags/Somalia.png").default, code: 'SO', dial_code: '+252' },
    { name: 'South Africa', flag: require("../../../images/country-flags/South-Africa.png").default, code: 'ZA', dial_code: '+27' },
    { name: 'South Sudan', flag: require("../../../images/country-flags/South-Sudan.png").default, code: 'SS', dial_code: '+211' },
    {
        name: 'South Georgia and the South Sandwich Islands',
        flag: require("../../../images/country-flags/South-Georgia-and-the-South-Sandwich-Islands.png").default,
        code: 'GS',
        dial_code: '+500',
    },
    { name: 'Spain', flag: require("../../../images/country-flags/Spain.png").default, code: 'ES', dial_code: '+34' },
    { name: 'Sri Lanka', flag: require("../../../images/country-flags/Sri-Lanka.png").default, code: 'LK', dial_code: '+94' },
    { name: 'Sudan', flag: require("../../../images/country-flags/Sudan.png").default, code: 'SD', dial_code: '+249' },
    { name: 'Suriname', flag: require("../../../images/country-flags/Suriname.png").default, code: 'SR', dial_code: '+597' },
    // { name: 'Svalbard and Jan Mayen', flag: '🇸🇯', code: 'SJ', dial_code: '+47' },
    { name: 'Swaziland', flag: require("../../../images/country-flags/Swaziland.png").default, code: 'SZ', dial_code: '+268' },
    { name: 'Sweden', flag: require("../../../images/country-flags/Sweden.png").default, code: 'SE', dial_code: '+46' },
    { name: 'Switzerland', flag: require("../../../images/country-flags/Switzerland.png").default, code: 'CH', dial_code: '+41' },
    { name: 'Syrian Arab Republic', flag: require("../../../images/country-flags/Syria.png").default, code: 'SY', dial_code: '+963' },

    { name: 't', title: true, code: 't', dial_code: '-1' },
    { name: 'Taiwan', flag: require("../../../images/country-flags/Taiwan.png").default, code: 'TW', dial_code: '+886' },
    { name: 'Tajikistan', flag: require("../../../images/country-flags/Tajikistan.png").default, code: 'TJ', dial_code: '+992' },
    {
        name: 'Tanzania',
        flag: require("../../../images/country-flags/Tanzania.png").default,
        code: 'TZ',
        dial_code: '+255',
    },
    { name: 'Thailand', flag: require("../../../images/country-flags/Thailand.png").default, code: 'TH', dial_code: '+66' },
    { name: 'Timor-Leste', flag: require("../../../images/country-flags/East-Timor.png").default, code: 'TL', dial_code: '+670' },
    { name: 'Togo', flag: require("../../../images/country-flags/Togo.png").default, code: 'TG', dial_code: '+228' },
    { name: 'Tokelau', flag: require("../../../images/country-flags/Tokelau.png").default, code: 'TK', dial_code: '+690' },
    { name: 'Tonga', flag: require("../../../images/country-flags/Tonga.png").default, code: 'TO', dial_code: '+676' },
    { name: 'Trinidad and Tobago', flag: require("../../../images/country-flags/Trinidad-and-Tobago.png").default, code: 'TT', dial_code: '+1868' },
    { name: 'Tunisia', flag: require("../../../images/country-flags/Tunisia.png").default, code: 'TN', dial_code: '+216' },
    { name: 'Turkey', flag: require("../../../images/country-flags/Turkey.png").default, code: 'TR', dial_code: '+90' },
    { name: 'Turkmenistan', flag: require("../../../images/country-flags/Turkmenistan.png").default, code: 'TM', dial_code: '+993' },

    { name: 'Tuvalu', flag: require("../../../images/country-flags/Tuvalu.png").default, code: 'TV', dial_code: '+688' },

    { name: 'u', title: true, code: 'u', dial_code: '-1' },
    { name: 'Uganda', flag: require("../../../images/country-flags/Uganda.png").default, code: 'UG', dial_code: '+256' },
    { name: 'Ukraine', flag: require("../../../images/country-flags/Ukraine.png").default, code: 'UA', dial_code: '+380' },
    { name: 'United Arab Emirates', flag: require("../../../images/country-flags/United-Arab-Emirates.png").default, code: 'AE', dial_code: '+971' },
    { name: 'United Kingdom', flag: require("../../../images/country-flags/United-Kingdom.png").default, code: 'GB', dial_code: '+44' },
    { name: 'United States', flag: require("../../../images/country-flags/United-States.png").default, code: 'US', dial_code: '+1' },
    { name: 'Uruguay', flag: require("../../../images/country-flags/Uruguay.png").default, code: 'UY', dial_code: '+598' },
    { name: 'Uzbekistan', flag: require("../../../images/country-flags/Uzbekistan.png").default, code: 'UZ', dial_code: '+998' },


    { name: 'v', title: true, code: 'v', dial_code: '-1' },
    { name: 'Vanuatu', flag: require("../../../images/country-flags/Vanuatu.png").default, code: 'VU', dial_code: '+678' },
    {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        flag: require("../../../images/country-flags/Venezuela.png").default,
        code: 'VE',
        dial_code: '+58',
    },
    { name: 'Vietnam', flag: require("../../../images/country-flags/Vietnam.png").default, code: 'VN', dial_code: '+84' },
    { name: 'Virgin Islands, British', flag: require("../../../images/country-flags/British-Virgin-Islands.png").default, code: 'VG', dial_code: '+1284' },
    { name: 'Virgin Islands, U.S.', flag: require("../../../images/country-flags/US-Virgin-Islands.png").default, code: 'VI', dial_code: '+1340' },

    { name: 'w', title: true, code: 'w', dial_code: '-1' },
    { name: 'Wallis and Futuna', flag: require("../../../images/country-flags/Wallis-And-Futuna.png").default, code: 'WF', dial_code: '+681' },

    { name: 'y', title: true, code: 'y', dial_code: '-1' },
    { name: 'Yemen', flag: require("../../../images/country-flags/Yemen.png").default, code: 'YE', dial_code: '+967' },

    { name: 'z', title: true, code: 'z', dial_code: '-1' },
    { name: 'Zambia', flag: require("../../../images/country-flags/Zambia.png").default, code: 'ZM', dial_code: '+260' },
    { name: 'Zimbabwe', flag: require("../../../images/country-flags/Zimbabwe.png").default, code: 'ZW', dial_code: '+263' },
]