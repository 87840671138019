import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	drawerLink: {},
	drawerLinkActive: {
		// color: 'white',
		// backgroundColor: '#d16632',
		// '&:hover, &:focus': {
		// 	backgroundColor: '#d16632',
		// 	color: '#fff',
		// },
	},
	link: {
		color: '#007eb0',
		paddingLeft: '30px',
		textDecoration: 'none',
		'&:hover, &:focus': {
			color: '#d16632',
		},
	},
	linkBold: {
		color: '#007eb0',
		paddingLeft: '30px',
		textDecoration: 'none',
		'&:hover, &:focus': {
			color: '#d16632',
		},
		fontWeight: "700"
	},
	linkActive: {
		backgroundColor: '#d16632',
		color: 'white',
		'&:hover, &:focus': {
			backgroundColor: '#d16632',
			color: 'white',
		},
	},
	linkNested: {
		// paddingLeft: 0,
		// '&:hover, &:focus': {
		// 	backgroundColor: '#d16632',
		// 	color: '#fff',
		// },
	},
	linkIconClose: {
		marginRight: theme.spacing(1),
		color: "theme.palette.text.secondary + '99'",
		transition: theme.transitions.create('color'),
		width: 24,
		display: 'flex',
		marginLeft: ' 10px',
		'&:hover, &:focus': {
			color: '#d16632',
		},
	},
	linkIcon: {
		marginRight: theme.spacing(1),
		color: "theme.palette.text.secondary + '99'",
		transition: theme.transitions.create('color'),
		width: 24,
		display: 'flex',
		justifyContent: 'center',
		'&:hover, &:focus': {
			color: '#d16632',
		},
	},
	linkIconActive: {
		color: theme.palette.primary.main,
	},
	linkText: {
		'&:hover, &:focus': {
			backgroundColor: '#d16632',
			color: '#fff',
		},
		padding: 0,
		color: '#007eb0',
		transition: theme.transitions.create(['opacity', 'color']),
		fontSize: 16,
	},
	linkTextActive: {
		color: '#fff',
	},

	linkTextHidden: {
		opacity: 0,
	},
	nestedList: {
		paddingLeft: theme.spacing(2) + 30,
	},
	sectionTitle: {
		marginLeft: theme.spacing(4.5),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(4),
		height: 1,
		backgroundColor: '#D8D8D880',
	},
}));
