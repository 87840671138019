import { makeStyles } from "@material-ui/styles";

const drawerWidth = 230;

export default makeStyles(theme => ({
  imgOnlineIcon :{
    backgroundColor: 'red',
    borderRadius: '100px',
    width: '15px',
    height: '15px',
    position: 'absolute',
    bottom: '12px',
    right: '14px',
    border: ' 2px solid white',
  },
  imgClass :{
    objectFit: 'cover', borderRadius: '130px', width: '100%', height: '140px'
  },
  imgMainContainer : {
    
    borderRadius: '100px',
    width: '60%',
    height: '140px',
    margin: '15px',
    position: 'relative',
  },
  imgMainDiv :{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center',
  },
  marginPadding: {
		margin: 0,
		padding: 0,
	},
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    background: '#f9f9f9',
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
