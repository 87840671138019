import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, FormHelperText, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";
import img from "./bg-1.jfif";
import logo from "./login.png";
import userInput from "./user.png";
import pass from "./Pass.png";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import apiServices from "../../services/requestHandler";
import localStoreUtil from "../../utils/localstore.util";
import { toast } from "react-toastify";
import { withStore } from "../../utils/store.util";
import { authClass } from "../../utils/auth.util";
import { askForPermissioToReceiveNotifications } from "../../push-notification";

function Login(props) {
  var classes = useStyles();
  let textInput = React.createRef();
  let textInputUserId = React.createRef();

  const [showPassword, setshowPassword] = useState(false);
  let [userID, setUserID] = useState();
  let [userPass, setUserPass] = useState();

  React.useEffect(() => {
    askForPermissioToReceiveNotifications(props);
  });
  
  React.useEffect(() => {
    const checkAgent = () => {
      if (authClass?.getUser?.token) {
        props.history.push("/app/orders");
      }
    };
    askForPermissioToReceiveNotifications(props);
    checkAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const pizzaApiCall = async () => {
      let { store } = props;
      let ResponseCheck = store.get("pizza");
      let data = "";
      if (ResponseCheck) {
        data = ResponseCheck;
      } else {
        const ownPizaResponse = await apiServices.getMakeYourOwnPiza();
        const pizzaResponse = await apiServices.getMenuByType("pizza");
        ownPizaResponse.data.data.options = ownPizaResponse.data.data.toppings;
        data = await pizzaResponse.data.data;
        data.unshift(ownPizaResponse.data.data);
        await store.setWithRender("pizza", data);
      }
    };
    pizzaApiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHanler = () => {
    setUserID(textInputUserId.current.value);
  };

  const onChangeHanler1 = () => {
    setUserPass(textInput.current.value);
  };

  const logInFun = async (e) => {
    e.preventDefault();
    let data = {
      userId: userID,
      password: userPass,
    };

    await props.store.setWithRender("lang", "en");
    await localStoreUtil.store_data("lang", "en");
    props.store.setWithRender("logOutDailogModel", false);

    const response = await apiServices.login(data);
    if (response.data.status === 200) {
      authClass.setUser = response.data.data;

      sessionStorage.setItem("session", true);
      document.cookie = "session = true";

      if (response.data.data.isNewAgent) {
        props.history.push({ pathname: "/reset-password", state: { userID } });
      } else {
        await localStoreUtil.store_data("agent", response.data.data);
        await localStoreUtil.store_data("token", response.data.data.token);
        let obj = {
          _id: response.data.data._id,
          deviceToken: await localStoreUtil.get_data("deviceToken"),
        };
        await apiServices.agentUpdate(obj);

        if (
          response.data.data.isStoreSupervisor &&
          !response.data.data.isAgent
        ) {
          props.history.push("/app/riders");
        } else {
          props.history.push("/app/orders");
        }
      }
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <>
      <form onSubmit={(e) => logInFun(e)} autoComplete="new-password">
        <div className={classes.overlay}>
          <img src={img} alt="background" className={classes.loginBackImg} />
          <div className={classes.logoLogin}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} alt="Logo" className={classes.logoSize} />
              </div>

              <h1
                style={{
                  color: "white",
                  marginTop: "40px",
                  marginBottom: "13px",
                }}
              >
                LOGIN TO ACCESS CALL CENTER
              </h1>

              <div
                style={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <FormHelperText
                    id="standard-weight-helper-text"
                    className={classes.lableSize}
                    style={{ marginLeft: "50px" }}
                  >
                    User ID
                  </FormHelperText>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={userInput}
                      alt="inputUser"
                      className="input-fields-logo"
                    />
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      className={classes.textBackground}
                      onChange={() => onChangeHanler()}
                      inputRef={textInputUserId}
                    />
                  </div>
                </div>

                <div style={{ marginTop: "15px" }}>
                  <FormHelperText
                    id="standard-weight-helper-text"
                    className={classes.lableSize}
                    style={{ marginLeft: "50px" }}
                  >
                    OTP/Password
                  </FormHelperText>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <img
                        src={pass}
                        alt="inputUser"
                        className="input-fields-logo"
                      />
                    </div>
                    <TextField
                      id={showPassword ? "outlined-basic" : "outlined-basic-on"}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      className={classes.textBackground}
                      size="small"
                      onChange={() => onChangeHanler1()}
                      type={"text"}
                      inputRef={textInput}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setshowPassword(!showPassword);
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div>
                  <FormHelperText
                    id="standard-weight-helper-text"
                    // className={classes.supervisorLoginLabel}
                    style={{
                      cursor: "pointer",
                      textAlign: "left",
                      marginLeft: "50px",
                      fontSize: "14px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      props.history.push("/supervisor-login");
                    }}
                  >
                    Login As Store Supervisor?
                  </FormHelperText>
                </div>

                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type={"submit"}
                      style={{
                        backgroundColor: "#0078AC",
                        fontFamily: "Bold",
                        fontSize: "20px",
                        color: "white",
                        marginTop: "20px",
                        marginLeft: "25px",
                        width: "50%",
                      }}
                      className={"btn-bg-container"}
                      variant={"contained"}
                      onClick={(e) => {
                        logInFun(e);
                      }}
                    >
                      LOGIN
                    </Button>
                  </div>

                  <div>
                    <FormHelperText
                      id="standard-weight-helper-text"
                      className={classes.forgotPasswordLabel}
                      style={{
                        marginLeft: "33px",
                        marginTop: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        props.history.push("/forget-password");
                      }}
                    >
                      Forgot Password?
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <span
              className="btn-floating btn-fb mx-1 "
              style={{
                textDecoration: "none",
                color: "#9c9c9c",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              &copy; 2019-{new Date().getFullYear()}{" "}
              <a
                href="http://www.hitech-prime.com/"
                className={classes.copyrightHover}
                target="blank"
              >
                HiTech Prime Private Limited
              </a>{" "}
              All rights reserved.
            </span>
          </div>
        </div>
      </form>
    </>
  );
}

export default withStore(withRouter(Login));
