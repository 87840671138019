import React, { Component } from "react";
import { CardHeader } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Card } from "@material-ui/core";
import apiServices from "../../services/requestHandler";
import { Switch } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from "react-toastify";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import UpdateIcon from "@material-ui/icons/Update";
import "../../App.css";
import { errorCapitalize } from "../../utils/functions";
import localStoreUtil from "../../utils/localstore.util";
import Loader from "../../components/ReUseableComponant/Loader";

export default class updateSubAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentDetail: {
        countryCode: "994",
        isSupervisor: false,
        isIntern: false,
        isAgent: false,
        isStoreSupervisor: false,
        supervisor: null,
        lang: "all",
        image: "",
        supervisorStores: [],
      },

      agentDetailError: {},
      isDisabled: false,
      getSupervisors: [],
      agents: [],

      stores: [],
      storeOptions: [],
      loader: true,
    };
  }

  async componentWillMount() {
    await this.getStore();
    await this.getAllAgents();

    this.setState({
      user: await localStoreUtil.get_data("token"),
    });

    const id = this.props.match.params.id;

    if (id) {
      this.agentInformation(id);

      await this.setState({
        agentDetail: {
          ...this.state.agentDetail,
          isNewAgent: false,
        },
        loader: false,
      });
    } else {
      await this.setState({ isDisabled: false });

      await this.setState({
        agentDetail: {
          ...this.state.agentDetail,
          active: true,
          isNewAgent: true,
        },
        loader: false,
      });
    }

    this.setState({
      loader: false,
    });
  }

  handleSwitchEdit = () => {
    try {
      this.setState((prevState) => ({
        isDisabled: !prevState.isDisabled,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  //Image Uploading Function
  uploadFile = async (file, name, stateName) => {
    let formData = new FormData();
    formData.append("file", file);
    try {
      let response = await apiServices.updateImage(formData);

      await this.setState({
        [stateName]: {
          ...this.state.agentDetail,
          [name]: response.data.data.path,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  getStore = async () => {
    try {
      const storeResponse = await apiServices.getAllStores();
      let options = [];
      storeResponse.data.data?.forEach((element) => {
        options.push({
          title: element.storeName.en,
          value: element._id,
        });
      });

      this.setState({ stores: storeResponse.data.data, storeOptions: options });
    } catch (error) {
      console.log(error);
    }
  };

  //Getting Supervisors
  getAllAgents = async () => {
    try {
      const response = await apiServices.getAgent();
      console.log(response.data.data, "hell");

      let filterSupervisor = response.data.data.filter(
        (item) => item.isSupervisor === true
      );
      const supervisorList = filterSupervisor.map((item) => {
        return {
          title: (item || {}).name || "",
          value: (item || {})._id,
        };
      });

      await this.setState({
        getSupervisors: supervisorList,
      });
    } catch (error) { }
  };

  //Agent Details
  agentInformation = async (id) => {
    try {
      const response = await apiServices.getAgentById(id);

      const storesData = this.state.storeOptions.filter((_stores) => {
        return response.data.data?.supervisorStores?.some((_responseStore) => {
          return _responseStore?.storeID?._id === _stores?.value;
        });
      });

      var output = [];

      for (var i = 0; i <= storesData.length; i++) {
        output.push(storesData.value);
      }

      await this.setState({
        agentDetail: {
          _id: this.props.match.params.id,
          agentID: response.data.data?.agentID,
          name: response.data.data?.name,
          active: response.data.data?.active,
          countryCode: "994",
          mobile: response.data.data?.mobile,
          email: response.data.data?.email,
          lang: response.data.data?.lang,
          image: response.data.data.image,
        },
        loader: false,
        isDisabled: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = async (event) => {
    await this.setState({ [event.target.name]: !event.target.checked });
  };

  handleInputChange = async (event) => {
    try {
      if (event.target.name === "isSupervisor" && event.target.value === true) {
        // eslint-disable-next-line
        this.state.agentDetail["isIntern"] = false;
        // eslint-disable-next-line
        this.state.agentDetail["supervisor"] = null;
      }

      if (event.target.name === "isStoreSupervisor") {
        // eslint-disable-next-line
        this.state.agentDetail["supervisorStores"] = [];
      }
      // eslint-disable-next-line
      this.state.agentDetail[event.target.name] = event.target.value;

      this.setState({
        [this.state.agentDetail["name"]]: event.target.value,
      });

      if (
        event.target.name === "password" ||
        event.target.name === "passwordConfirm"
      ) {
        if (
          this.state.agentDetail["password"] !==
          this.state.agentDetail["passwordConfirm"]
        ) {
          // eslint-disable-next-line
          this.state.agentDetailError[event.target.name] = true;
        } else {
          // eslint-disable-next-line
          this.state.agentDetailError[event.target.name] = false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleStoreTags = (stateName, e, values) => {
    try {
      // eslint-disable-next-line
      this.state.agentDetail[stateName] = values.map((item) => item.value);
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = async () => {
    try {
      const { agentDetail } = this.state;

      var response = "";

      if (!agentDetail.agentID) {
        toast.error("Please Enter Agent ID");
      } else if (!agentDetail.name) {
        toast.error("Please Enter Agent Name");
      } else if (!agentDetail.mobile) {
        toast.error("Please Enter Agent Mobile");
      } else if (!agentDetail.email) {
        toast.error("Please Enter Agent E-Mail");
      } else if (!agentDetail.lang) {
        toast.error("Please Select Agent Language");
      } else {
        if (this.props.match.params.id) {
          //eslint-disable-next-line
          response = await apiServices.updateAgent(agentDetail);

          if (response.data.status === 200) {
            toast.success("Agent Updated Successfully");
            this.props.history.push({ pathname: "/app/agents" });
          } else {
            let errorMessage = response.data.error.substring(
              response.data.error.indexOf("{")
            );
            let dupError = errorMessage.replace(/[{}"]/g, "");
            dupError = dupError.replace("agentID:", "Agent ID:");
            toast.error("Duplicate " + errorCapitalize(dupError));
          }
        } else {
          response = await apiServices.addAgent(agentDetail);
          if (response.data.status === 200) {
            toast.success("Agent Added Successfully");
            this.props.history.push({ pathname: "/app/agents" });
          } else {
            let errorMessage = response.data.error.substring(
              response.data.error.indexOf("{")
            );
            let dupError = errorMessage.replace(/[{}"]/g, "");
            dupError = dupError.replace("agentID:", "Agent ID:");
            toast.error("Duplicate " + errorCapitalize(dupError));
          }
        }
      }
    } catch (error) {
      this.setState({ loader: false });
      // var errorMessage = error.data.error.substring(
      //   error.data.error.indexOf("{")
      // );
      // let dupError = errorMessage.replace(/[{}"]/g, "");
      // toast.error("Duplicate " + errorCapitalize(dupError));
    }
  };

  render() {
    const agent_ID = this.props.match.params.id;

    return (
      <>
        {this.state.loader === false ? (
          <div style={{ backgroundColor: "white" }}>
            <div className="res-container">
              <Card elevation={2}>
                <CardHeader
                  className={"blue-background"}
                  title={"Agent Information"}
                  action={
                    agent_ID ? (
                      <FormControlLabel
                        label="EDIT"
                        control={
                          <Switch
                            checked={!this.state.isDisabled}
                            onChange={this.handleSwitchEdit}
                            className={"switchBtn"}
                            name="isDisabled"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        }
                      />
                    ) : (
                      ""
                    )
                  }
                />

                <form method="post" action="/form" autoComplete="off">
                  <div className={"padding-con"}>
                    <Grid container spacing={2}>
                      <Grid xs={12} md={6} item>
                        <TextField
                          inputProps={
                            ({
                              autocomplete: "off",
                            },
                              this.state.agentDetail.agentID
                                ? { maxLength: 4 }
                                : { maxLength: 4 },
                            {
                              readOnly: true,
                            })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={"Agent ID"}
                          placeholder={"0000"}
                          fullWidth
                          variant={"outlined"}
                          type={"text"}
                          name={"agentID"}
                          id={"agentTxt"}
                          value={this.state.agentDetail.agentID}
                          onChange={(event) => this.handleInputChange(event)}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          style={{
                            fontFamily: "OpenSans-Regular",
                            color: "black",
                            backgroundColor: "white",
                            fontSize: "16px"
                          }}
                          onClick={() => toast.info("Read Only")}
                        />
                      </Grid>

                      {agent_ID ? (
                        <Grid xs={12} md={6} item>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel
                              style={{
                                shrink: true,
                                fontWeight: 700,
                                fontFamily: "OpenSans-Regular",
                                color: "black",
                                backgroundColor: "white",
                                fontSize: "18px",
                              }}
                            >
                              Status
                            </InputLabel>
                            <Select
                              fullWidth
                              name="active"
                              value={
                                this.state.agentDetail.active === true
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                style: {
                                  marginTop: "-130px",
                                },
                              }}
                              inputProps={{
                                readOnly: this.state.isDisabled ? true : false,
                              }}
                              style={{ fontSize: "12px" }}
                              id={"agentTxt"}
                            >
                              <MenuItem value={null} disabled={true}>
                                Select
                              </MenuItem>
                              <MenuItem value={true}>Active</MenuItem>
                              <MenuItem value={false}>In Active</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : null}

                      <Grid xs={12} md={6} item>
                        <TextField
                          inputProps={{
                            autocomplete: "off",
                            readOnly: this.state.isDisabled ? true : false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={"Name"}
                          placeholder={"Denis Aliyev"}
                          fullWidth
                          variant={"outlined"}
                          type={"text"}
                          name={"name"}
                          id={"agentTxt"}
                          value={this.state.agentDetail.name}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </Grid>

                      <Grid xs={12} md={6} item>
                        <TextField
                          inputProps={{
                            autocomplete: "off",
                            readOnly: this.state.isDisabled ? true : false,
                            minLength: 9,
                            maxLength: 9,
                          }}
                          InputLabelProps={{ shrink: true }}
                          label={"Mobile"}
                          placeholder={"550090909"}
                          fullWidth
                          variant={"outlined"}
                          type={"text"}
                          name={"mobile"}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormControlLabel
                                  style={{
                                    cursor: "default",
                                    paddingLeft: "10px",
                                    marginRight: "0px",
                                    marginTop: "0px",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                  }}
                                  control={<span>+994</span>}
                                />
                              </InputAdornment>
                            ),
                          }}
                          id={"agentTxt"}
                          value={this.state.agentDetail.mobile}
                          onChange={(event) => this.handleInputChange(event)}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={6} item>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label={"Email"}
                          fullWidth
                          variant={"outlined"}
                          type={"email"}
                          name={"email"}
                          id={"agentTxt"}
                          placeholder="Email"
                          value={this.state.agentDetail.email}
                          onChange={(event) => this.handleInputChange(event)}
                          inputProps={{
                            readOnly: this.state.isDisabled ? true : false,
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel
                            style={{
                              shrink: true,
                              fontWeight: 700,
                              fontFamily: "OpenSans-Regular",
                              color: "black",
                              backgroundColor: "white",
                              fontSize: "18px",
                            }}
                          >
                            Is Agent
                          </InputLabel>
                          <Select
                            fullWidth
                            name="isAgent"
                            value={
                              this.state.agentDetail.isAgent === true
                                ? true
                                : false
                            }
                            onChange={(event) => this.handleInputChange(event)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            inputProps={{
                              readOnly: true,
                            }}
                            onClick={() => toast.info("Read Only")}
                            id={"agentTxt"}
                            style={{ fontSize: "12px" }}
                          >
                            <MenuItem value={""} disabled={true}>
                              Select
                            </MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* {this.state.agentDetail?.isAgent ? (
                        <>
                          <Grid xs={12} md={6} item>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                style={{
                                  shrink: true,
                                  fontWeight: 700,
                                  fontFamily: "OpenSans-Regular",
                                  color: "black",
                                  backgroundColor: "white",
                                }}
                              >
                                Is Agent Supervisor
                              </InputLabel>
                              <Select
                                fullWidth
                                name="isSupervisor"
                                value={
                                  this.state.agentDetail.isSupervisor === true
                                    ? true
                                    : false
                                }
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                  style: { marginTop: "80px" },
                                }}
                                inputProps={{
                                  readOnly: this.state.isDisabled
                                    ? true
                                    : false,
                                }}
                                disabled={
                                  this.state?.agentDetail?.isAgent === false
                                    ? true
                                    : this.state?.agentDetail?.isIntern ===
                                      undefined
                                    ? true
                                    : this.state?.agentDetail?.isIntern === true
                                    ? true
                                    : false
                                }
                                style={{ fontSize: "12px" }}
                                id={"agentTxt"}
                              >
                                <MenuItem value={""} disabled={true}>
                                  Select
                                </MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid xs={12} md={6} item>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                style={{
                                  shrink: true,
                                  fontWeight: 700,
                                  fontFamily: "OpenSans-Regular",
                                  color: "black",
                                  backgroundColor: "white",
                                }}
                              >
                                Is Trainee Agent
                              </InputLabel>
                              <Select
                                fullWidth
                                name="isIntern"
                                value={
                                  this.state.agentDetail.isIntern === true
                                    ? true
                                    : false
                                }
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                  style: { marginTop: "80px" },
                                }}
                                disabled={
                                  this.state?.agentDetail?.isAgent === false
                                    ? true
                                    : this.state?.agentDetail?.isSupervisor ===
                                      undefined
                                    ? true
                                    : this.state?.agentDetail?.isSupervisor ===
                                      true
                                    ? true
                                    : false
                                }
                                inputProps={{
                                  readOnly: this.state.isDisabled
                                    ? true
                                    : false,
                                }}
                                id={"agentTxt"}
                                style={{ fontSize: "12px" }}
                              >
                                <MenuItem value={""} disabled={true}>
                                  Select
                                </MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid xs={12} md={6} item>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                style={{
                                  shrink: true,
                                  fontWeight: 700,
                                  fontFamily: "OpenSans-Regular",
                                  color: "black",
                                  backgroundColor: "white",
                                }}
                              >
                                Agent Supervisor
                              </InputLabel>
                              <Select
                                fullWidth
                                name="supervisor"
                                value={`${this.state.agentDetail.supervisor}`}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                  style: { marginTop: "140px" },
                                }}
                                disabled={
                                  this.state?.agentDetail?.isAgent === false
                                    ? true
                                    : this.state?.agentDetail?.isSupervisor ===
                                      undefined
                                    ? true
                                    : this.state?.agentDetail?.isSupervisor ===
                                      true
                                    ? true
                                    : false
                                }
                                inputProps={{
                                  readOnly: this.state.isDisabled
                                    ? true
                                    : false,
                                }}
                                id={"agentTxt"}
                                style={{ fontSize: "12px" }}
                              >
                                <MenuItem value={null} disabled={true}>
                                  Select Agent Supervisor
                                </MenuItem>
                                {this.state.getSupervisors.map((item) => (
                                  <MenuItem value={item.value}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : null} */}

                      <Grid xs={12} md={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel
                            style={{
                              shrink: true,
                              fontWeight: 700,
                              fontFamily: "OpenSans-Regular",
                              color: "black",
                              backgroundColor: "white",
                              fontSize: "18px",
                            }}
                          >
                            Language
                          </InputLabel>
                          <Select
                            fullWidth
                            name="lang"
                            value={this.state.agentDetail.lang || ""}
                            onChange={(event) => this.handleInputChange(event)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              style: { marginTop: "145px" },
                            }}
                            inputProps={{
                              readOnly: this.state.isDisabled ? true : false,
                            }}
                            style={{ fontSize: "12px" }}
                            id={"agentTxt"}
                          >
                            <MenuItem value={"all"} disabled={true}>
                              Select Language
                            </MenuItem>
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"az"}>Azeri</MenuItem>
                            <MenuItem value={"ru"}>Russian</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* <Grid xs={12} md={6} item>
                    <div className="agent-upload">
                      {this.state.agentDetail.image === "" ? (
                        <FileUpload
                          className="agentUploadFile"
                          uploadFile={this.uploadFile}
                          name="image"
                          stateName="agentDetail"
                          StartIcon={<CloudUploadIcon />}
                          message="Upload Image"
                          disabled={this.state.isDisabled}
                        />
                      ) : (
                        <div className="img">
                          <FileUpload
                            className="agentUploadFile"
                            uploadFile={this.uploadFile}
                            name="image"
                            stateName="agentDetail"
                            iconImage={this.state.agentDetail.image}
                            message="Upload Image"
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      )}
                    </div>
                  </Grid> */}

                      <Grid xs={12} item className="buttonRight">
                        {agent_ID ? (
                          <>
                            <Button
                              className={"btn-blue-outlined return-to-list"}
                              variant="contained"
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/app/agents`,
                                })
                              }
                              startIcon={<ArrowBackIcon />}
                            >
                              Return to List
                            </Button>

                            {this.state.isDisabled === false ? (
                              <Button
                                type={"button"}
                                className={"btn-blue-outlined"}
                                variant="contained"
                                onClick={() => this.onSubmit()}
                                startIcon={<UpdateIcon />}
                              >
                                Update Agent
                              </Button>
                            ) : null}
                          </>
                        ) : (
                          <Button
                            type={"button"}
                            className={"btn-blue-outlined"}
                            variant="contained"
                            onClick={() => this.onSubmit()}
                            startIcon={<AddCircleIcon />}
                          >
                            Add Agent
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        ) : (
          <Loader open={this.state.loader} />
        )}
      </>
    );
  }
}
